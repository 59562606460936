@import url('../fonts/Source+Sans+Pro:400,600,300italic.css');
@font-face {
  font-family: 'cantata_oneregular';
  src: url('../fonts/cantataone-regular.eot');
  src: url('../fonts/cantataone-regular.eot') format('embedded-opentype'), url('../fonts/cantataone-regular.woff') format('woff'), url('../fonts/cantataone-regular.ttf') format('truetype'), url('../fonts/cantataone-regular.svg#cantata_oneregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.hcard {
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: -100%;
  width: 1px;
}
.hideme {
  display: none!important;
}
.invisible {
  visibility: hidden;
}
#javascript {
  background: #ff0000;
  color: #fff;
  font-size: 15px;
  padding: 30px;
  text-align: center;
}
html {
  font-size: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  text-rendering: optimizeLegibility;
}
body.mobile,
body.responsive {
  word-wrap: break-word;
}
html,
button,
input,
select,
textarea {
  font-family: inherit;
}
a {
  cursor: pointer;
}
a:active,
a:focus,
a:hover,
a[tabindex="-1"] {
  outline: 0;
}
address,
em {
  font-style: normal;
}
strong {
  font-weight: normal;
}
u {
  text-decoration: none;
}
sub,
sup {
  font-size: 12px;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -5px;
}
sup {
  top: -8px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}
p,
blockquote {
  margin: 0;
}
small {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
}
caption,
th {
  text-align: left;
}
td,
th {
  vertical-align: top;
}
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
dd,
dl,
dt,
li,
ol,
ul {
  margin: 0;
  padding: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}
label {
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input[type=date],
input[type=file],
input[type=text],
input[type=time],
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button:focus,
input[type=date]:focus,
input[type=file]:focus,
input[type=text]:focus,
input[type=time]:focus,
select:focus,
textarea:focus {
  outline: 0;
}
button {
  cursor: pointer;
  width: auto;
  -webkit-appearance: none;
  *overflow: visible;
}
button:active {
  outline: 0;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type=checkbox],
input[type=radio] {
  padding: 0;
  vertical-align: middle;
  *height: 13px;
  *width: 13px;
}
optgroup {
  font-style: normal;
  font-weight: normal;
}
optgroup::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: none;
}
img {
  border: 0;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  -ms-interpolation-mode: bicubic;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
.container {
  margin: 0 auto;
  width: 920px;
  *zoom: 1;
}
.container:after {
  clear: both;
}
.container:after,
.container:before {
  content: '';
  display: table;
}
.row {
  margin-left: -40px;
  *zoom: 1;
}
.row:after {
  clear: both;
}
.row:after,
.row:before {
  content: '';
  display: table;
}
[class*=span] {
  float: left;
  margin-left: 40px;
  min-height: 1px;
}
.span10 {
  width: 120px;
}
.span11 {
  width: 136px;
}
.span12 {
  width: 152px;
}
.span13 {
  width: 168px;
}
.span14 {
  width: 184px;
}
.span15 {
  width: 200px;
}
.span16 {
  width: 216px;
}
.span17 {
  width: 232px;
}
.span18 {
  width: 248px;
}
.span19 {
  width: 264px;
}
.span20 {
  width: 280px;
}
.span21 {
  width: 296px;
}
.span22 {
  width: 312px;
}
.span23 {
  width: 328px;
}
.span24 {
  width: 344px;
}
.span25 {
  width: 360px;
}
.span26 {
  width: 376px;
}
.span27 {
  width: 392px;
}
.span28 {
  width: 408px;
}
.span29 {
  width: 424px;
}
.span30 {
  width: 440px;
}
.span31 {
  width: 456px;
}
.span32 {
  width: 472px;
}
.span33 {
  width: 488px;
}
.span34 {
  width: 504px;
}
.span35 {
  width: 520px;
}
.span36 {
  width: 536px;
}
.span37 {
  width: 552px;
}
.span38 {
  width: 568px;
}
.span39 {
  width: 584px;
}
.span40 {
  width: 600px;
}
.span41 {
  width: 616px;
}
.span42 {
  width: 632px;
}
.span43 {
  width: 648px;
}
.span44 {
  width: 664px;
}
.span45 {
  width: 680px;
}
.span46 {
  width: 696px;
}
.span47 {
  width: 712px;
}
.span48 {
  width: 728px;
}
.span49 {
  width: 744px;
}
.span50 {
  width: 760px;
}
.span60 {
  width: 920px;
}
.offset10 {
  margin-left: 200px;
}
.offset11 {
  margin-left: 216px;
}
.offset12 {
  margin-left: 232px;
}
.offset13 {
  margin-left: 248px;
}
.offset14 {
  margin-left: 264px;
}
.offset15 {
  margin-left: 280px;
}
.offset16 {
  margin-left: 296px;
}
.offset17 {
  margin-left: 312px;
}
.offset18 {
  margin-left: 328px;
}
.offset19 {
  margin-left: 344px;
}
.offset20 {
  margin-left: 360px;
}
.offset21 {
  margin-left: 376px;
}
.offset22 {
  margin-left: 392px;
}
.offset23 {
  margin-left: 408px;
}
.offset24 {
  margin-left: 424px;
}
.offset25 {
  margin-left: 440px;
}
.offset26 {
  margin-left: 456px;
}
.offset27 {
  margin-left: 472px;
}
.offset28 {
  margin-left: 488px;
}
.offset29 {
  margin-left: 504px;
}
.offset30 {
  margin-left: 520px;
}
.offset31 {
  margin-left: 536px;
}
.offset32 {
  margin-left: 552px;
}
.offset33 {
  margin-left: 568px;
}
.offset34 {
  margin-left: 584px;
}
.offset35 {
  margin-left: 600px;
}
.offset36 {
  margin-left: 616px;
}
.offset37 {
  margin-left: 632px;
}
.offset38 {
  margin-left: 648px;
}
.offset39 {
  margin-left: 664px;
}
.offset40 {
  margin-left: 680px;
}
.offset41 {
  margin-left: 696px;
}
.offset42 {
  margin-left: 712px;
}
.offset43 {
  margin-left: 728px;
}
.offset44 {
  margin-left: 744px;
}
.offset45 {
  margin-left: 760px;
}
.offset46 {
  margin-left: 776px;
}
.offset47 {
  margin-left: 792px;
}
.offset48 {
  margin-left: 808px;
}
.offset49 {
  margin-left: 824px;
}
.offset50 {
  margin-left: 840px;
}
html {
  line-height: 24px;
  font-size: 15px;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
.no-fontsmoothing {
  font-family: Arial, Helvetica, sans-serif;
}
#sitetitle {
  font-size: 21px;
  line-height: 24px;
  margin-bottom: 24px;
  padding-top: 0;
}
h1,
h2,
h3 {
  padding-top: 6px;
  margin-bottom: 18px;
}
h1,
h2 {
  font-size: 17px;
}
h3 {
  font-size: 15px;
}
h4,
h5,
h6 {
  font-size: 15px;
}
p {
  margin-bottom: 24px;
}
blockquote {
  margin-bottom: 24px;
}
blockquote p {
  font-style: italic;
  margin-bottom: 0;
}
blockquote p:before {
  content: '\201E';
}
blockquote p:after {
  content: '\201C';
}
blockquote small:before {
  content: '\2013\A0';
}
table {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 23px;
}
th,
td {
  border-top-style: solid;
  border-top-width: 1px;
  padding-bottom: 12px;
  padding-top: 11px;
}
dd,
dl,
ol,
ul {
  margin-bottom: 24px;
}
li > ul,
li > ol {
  margin-bottom: 0;
}
form.content_full_width .form_entry.checkbox label {
  width: auto;
}
form.content_full_width label {
  width: 225px;
}
form.content_full_width input[type=date],
form.content_full_width input[type=file],
form.content_full_width input[type=text],
form.content_full_width input[type=time],
form.content_full_width select {
  width: 343px;
}
form.content_full_width textarea {
  width: 583px;
}
form.content_full_width select {
  width: 360px;
}
form.content_full_width .error_msg {
  padding-left: 240px;
}
.form_section {
  margin-bottom: 12px;
  *zoom: 1;
}
.form_section:after {
  clear: both;
}
.form_section:after,
.form_section:before {
  content: '';
  display: table;
}
.form_entry {
  font-size: 0;
}
.form_entry.checkbox label,
.form_entry.radio label {
  vertical-align: middle;
  width: auto!important;
}
.form_entry.radio label {
  white-space: nowrap;
}
.form_entry .error_msg {
  font-size: 15px;
  margin-top: 4px;
  padding-left: 176px;
}
.form_entry .error_msg.checkbox,
.form_entry .error_msg.radio {
  margin: 0;
}
.form_entry {
  min-height: 33px;
  padding-bottom: 12px;
}
.form_multiple_entry {
  min-height: 33px;
}
.form_multiple_entry.clone {
  padding-bottom: 0;
}
.form_multiple_entry .remove {
  margin-left: 240px;
}
label {
  display: inline-block;
  font-size: 15px;
  margin-right: 15px;
  width: 161px;
}
input[type=date],
input[type=file],
input[type=text],
input[type=time],
select,
textarea {
  border-style: solid;
  border-width: 1px;
  padding: 7px;
  width: 247px;
  *width: 246px;
}
input[type=text] {
  height: 21px;
  line-height: 21px;
}
input[type=checkbox],
input[type=radio] {
  margin-right: 8px;
}
select {
  padding: 8px 8px;
  width: 264px;
}
.form_buttons {
  margin-bottom: 24px;
}
.hide_label label {
  display: none !important;
}
.hide_label textarea {
  width: 583px;
}
#disclaimer,
.datepicker {
  font-size: 15px;
}
body.mobile .form_entry.checkbox label,
body.mobile .form_entry.radio label {
  display: inline-block;
  width: 80%;
}
body.mobile .form_multiple_entry .remove {
  margin-left: 0;
}
body.mobile label {
  display: block;
  margin: 0;
}
body.mobile .form_entry input[type=date],
body.mobile .form_entry input[type=file],
body.mobile .form_entry input[type=text],
body.mobile .form_entry input[type=time],
body.mobile .form_entry select,
body.mobile .form_entry textarea {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body.mobile .form_buttons button {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.address .fax,
.address .mail,
.address .phone,
.address .website {
  display: inline-block;
  width: 50px;
}
.address .phone + a {
  border-bottom: none;
}
.address .phone + span > a {
  border-bottom: none;
}
.address.atikon {
  font-size: 13px;
  line-height: 19px;
}
.address.atikon h3 {
  font-size: 13px!important;
}
.atikon_logo {
  margin: 5px 0 24px;
  height: 48px;
  width: 160px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: 0px -32px;
}
a[href$=".vcf"] .icon {
  float: left;
  height: 16px;
  margin: 5px 5px 0 0;
  width: 16px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -384px;
}
a[href$=".vcf"] .text {
  display: inline-block;
  height: 18px;
  overflow: hidden;
}
body.mobile .address .fax,
body.mobile .address .mail,
body.mobile .address .phone,
body.mobile .address .website {
  width: 55px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  a[href$=".vcf"] .icon {
    background-size: 224px 576px;
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -384px;
  }
}
.datepicker {
  background: #fff;
  border: 1px solid #676d6e;
  box-shadow: 0 0 10px #e9eaeb;
  padding: 15px;
  position: relative;
  border-radius: 4px;
  background-clip: padding-box;
}
.datepicker table {
  margin: 3px 0 0;
}
.datepicker td,
.datepicker th {
  padding: 4px 8px;
  text-align: center;
}
.dp_box {
  overflow: hidden;
  position: relative;
}
input[type=text].date {
  text-indent: 25px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -128px -224px;
}
.dp_active {
  background: #bde5e1;
}
.dp_clickable_day {
  cursor: pointer;
}
.dp_clickable_day:hover {
  background: #bde5e1;
}
.dp_past_day {
  color: #c2c5c6;
}
.dp_today {
  background: #35877f;
  color: #fff;
  font-weight: bold;
}
.dp_weekend {
  color: #c2c5c6;
}
.dp_next_month {
  position: absolute;
  top: 0;
  right: 0;
}
.dp_prev_month {
  left: 0;
  position: absolute;
  top: 0;
}
.dp_month {
  color: #35877f;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  input[type=text].date {
    background-size: 224px 576px;
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -128px -224px;
  }
}
.download {
  /*    background: url('../images/default/doubleline.gif') 0 top repeat-x;
    clear: both;
    margin-bottom: @line_height;
    padding-top: @line_height;
*/
}
.download a {
  border-bottom: none;
}
.download a .text {
  display: inline-block;
}
.download ul {
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.download ul li {
  background: none;
  padding-left: 0;
}
.download .break {
  clear: both;
  margin-left: 0;
}
a.online .icon {
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -416px;
}
a[href$=".pdf"] .icon {
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -480px;
}
a[href$=".xls"] .icon {
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -448px;
}
a .icon {
  float: left;
  height: 16px;
  margin: 3px 5px 0 0;
  width: 16px;
}
a .text {
  display: block;
  overflow: hidden;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .icon {
    background-size: 224px 576px;
  }
  a.online .icon {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -416px;
  }
  a[href$=".pdf"] .icon {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -480px;
  }
  a[href$=".xls"] .icon {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -448px;
  }
}
figure {
  margin: 0 0 24px 0;
}
figure.center {
  text-align: center;
}
figure.floatleft {
  float: left;
  margin: 0 15px 24px 0;
}
figure.floatright {
  float: right;
  margin: 0 0 24px 15px;
}
figure a.lightbox {
  display: inline-block;
}
figure.right {
  text-align: right;
}
figure.text {
  margin-bottom: 0;
}
figcaption {
  text-align: left;
}
figcaption.center {
  margin: 0 auto;
}
figcaption.right {
  margin-left: auto;
}
input[type=date],
input[type=file],
input[type=text],
input[type=time],
select,
textarea {
  background-color: #fff;
  border: 1px solid #e9eaeb;
  box-shadow: 0 1px 0 #fff;
  color: #cfd2d2;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 15px;
  border-radius: 4px;
  background-clip: padding-box;
}
input[type=date]:hover,
input[type=file]:hover,
input[type=text]:hover,
input[type=time]:hover,
select:hover,
textarea:hover {
  background-color: #fff;
  border-color: #35877f;
  color: #cfd2d2;
}
input[type=date]:focus,
input[type=file]:focus,
input[type=text]:focus,
input[type=time]:focus,
select:focus,
textarea:focus,
input[type=date].focus,
input[type=file].focus,
input[type=text].focus,
input[type=time].focus,
select.focus,
textarea.focus {
  background-color: #fff;
  border-color: #35877f;
  box-shadow: 0 1px 2px #86cfc8 inset, 0 -1px 1px #98d7d0 inset, 0 1px 0 #fff;
  color: #676d6e;
}
input[type=date],
input[type=file],
input[type=text],
input[type=time],
label {
  margin-right: 0!important;
  width: 260px;
}
input[type=text].placeholder {
  color: #dcdede;
}
button {
  background-color: #efefef;
  border: none;
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  font-size: 14px;
  list-style: none;
  padding: 0 15px;
  text-decoration: none;
  height: 33px;
  line-height: 29px;
}
button.focus,
button:focus {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
  outline: 0;
}
button:hover {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
button:active {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
button.back {
  margin-right: 15px;
}
.form_footer {
  border-top: 1px solid #e9eaeb;
  padding-top: 23px;
  *zoom: 1;
}
.form_footer:after {
  clear: both;
}
.form_footer:after,
.form_footer:before {
  content: '';
  display: table;
}
.form_buttons {
  float: right;
}
.form_multiple_entries .add,
.form_multiple_entries .remove {
  display: block;
}
.form_multiple_entries .add .icon,
.form_multiple_entries .remove .icon {
  float: left;
  height: 16px;
  margin: 4px 5px 0 0;
  width: 16px;
}
.form_multiple_entries .add .text,
.form_multiple_entries .remove .text {
  display: block;
  overflow: hidden;
}
.form_multiple_entries .add {
  margin-bottom: 24px;
}
.form_multiple_entries .add .icon {
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -320px;
}
.form_multiple_entries .remove {
  margin-bottom: 12px;
}
.form_multiple_entries .remove .icon {
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -288px;
}
.form_multiple_entry.clone {
  border-top: 1px solid #e9eaeb;
  padding-top: 12px;
}
#disclaimer h2 {
  display: none;
}
#disclaimer p {
  margin: 24px 0 0;
}
.error input[type=text],
.error select,
.error textarea {
  background-color: #fffafa;
  border-color: #d47d7d;
  color: #a80000;
}
.okay input[type=text],
.okay select,
.okay textarea {
  background-color: #fafffa;
  border-color: #7dd485;
  color: #00a810;
}
.error_msg,
.required_asterisk {
  color: #a80000;
}
body.mobile .form_multiple_entry.clone {
  border: 0;
  padding: 0;
}
body.mobile .form_footer {
  border: 0;
  padding: 0;
}
body.mobile .form_buttons {
  display: inline-block;
  float: none;
}
body.mobile select {
  color: #676d6e;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .form_multiple_entries .icon {
    background-size: 224px 576px;
  }
  .form_multiple_entries .add .icon {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -320px;
  }
  .form_multiple_entries .remove .icon {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -288px;
  }
}
.google_maps img {
  max-height: none;
  max-width: none;
}
.google_maps .map {
  height: 400px;
}
.google_maps .direction .adp-warnbox {
  margin-bottom: 24px;
}
.google_maps .direction .adp-warnbox .warnbox-c1,
.google_maps .direction .adp-warnbox .warnbox-c2 {
  display: none;
}
.google_maps .direction .adp-warnbox .warnbox-content {
  background: none;
  border: 1px solid #e9eaeb;
  padding: 15px;
  border-radius: 4px;
  background-clip: padding-box;
}
.google_maps .direction .saddr {
  float: left;
  margin-right: 15px;
}
.google_maps .direction .saddr input {
  *position: relative;
  *top: -1px;
}
.google_maps .direction .daddr {
  float: left;
  margin-right: 15px;
}
.google_maps .direction form {
  margin: 15px 0 14px;
  *zoom: 1;
}
.google_maps .direction form:after {
  clear: both;
}
.google_maps .direction form:after,
.google_maps .direction form:before {
  content: '';
  display: table;
}
.google_maps .direction .adp-directions {
  width: 100%;
}
.google_maps .direction .adp-directions tr td {
  border-color: #e9eaeb;
  font-size: 15px;
  border-top-style: solid;
  border-top-width: 1px;
  padding-bottom: 12px;
  padding-top: 11px;
}
.google_maps .direction .adp-directions tr td div {
  font-size: 14px !important;
  line-height: 22px !important;
}
.google_maps .direction .adp-directions tr td + td + td,
.google_maps .direction .adp-directions tr td:first-child {
  text-align: right;
}
.google_maps .direction .adp-legal {
  color: #676d6e;
  font-size: 14px;
  line-height: 22px;
}
.google_maps .direction .adp-placemark {
  background: none;
  border-color: #e9eaeb;
  color: #676d6e;
  margin: 0 0 24px 0;
}
.google_maps .direction .adp-placemark td,
.google_maps .direction .adp-placemark .adp-text {
  padding-left: 15px;
}
.google_maps .direction .adp-substep {
  padding: 0 15px 0 0;
}
.google_maps .direction .adp-summary {
  margin-bottom: 15px;
  text-align: right;
}
.travel_mode_list {
  float: left;
  margin-right: 15px;
  *zoom: 1;
}
.travel_mode_list:after {
  clear: both;
}
.travel_mode_list:after,
.travel_mode_list:before {
  content: '';
  display: table;
}
.travel_mode_list a {
  display: block;
  position: relative;
  *top: 1px;
  text-indent: -99999px;
  overflow: hidden;
  background-color: #efefef;
  border: none;
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  font-size: 14px;
  list-style: none;
  padding: 0 15px;
  text-decoration: none;
}
.travel_mode_list a span {
  display: block;
  width: 32px;
  height: 31px;
  line-height: 31px;
}
.travel_mode_list a.active {
  z-index: 10;
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
.travel_mode_list a:focus {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
  outline: 0;
}
.travel_mode_list a:hover {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
.travel_mode_list a.afoot {
  float: left;
  left: -1px;
}
.travel_mode_list a.afoot span {
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: 0px -160px;
}
.travel_mode_list a.afoot.active span {
  background-position: -64px -160px;
}
.travel_mode_list a.car {
  float: left;
}
.travel_mode_list a.car span {
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -128px -160px;
}
.travel_mode_list a.car.active span {
  background-position: -192px -160px;
}
body.mobile .google_maps .map {
  height: 300px;
  width: 90%;
}
body.mobile .google_maps .saddr,
body.mobile .google_maps .daddr {
  float: none;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .travel_mode_list a span {
    background-size: 224px 576px;
  }
  .travel_mode_list a.afoot span {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: 0px -160px;
  }
  .travel_mode_list a.car span {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -128px -160px;
  }
}
.infolist_search,
.infolist_select {
  margin-bottom: 24px;
}
.infolist_select label,
.infolist_select select {
  width: auto;
}
.infolist_backlink {
  float: right;
}
.infolist_dropdown {
  margin-bottom: 8px;
}
.infolist_dropdown.last {
  margin-bottom: 24px;
}
.infolist_dropdown h2 {
  background-color: #efefef;
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  display: inline-block;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 0;
  padding: 8px 15px;
  text-decoration: none;
  display: block;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  font-size: 14px;
}
.infolist_dropdown h2:hover {
  background-color: #e2e2e2;
  color: #35877f;
  cursor: pointer;
}
.infolist_dropdown.active h2 {
  background-color: #e2e2e2;
  color: #35877f;
}
.infolist_dropdown .content {
  background: url('../images/default/doubleline.gif') 0 bottom repeat-x;
  margin-bottom: 24px;
  padding: 0 15px 1px;
}
.infolist_dropdown .content > *:first-child {
  padding-top: 15px;
}
.infolist_abc .infolist_overview ul,
.vorsteuerabzugsberechtigte_kfz .infolist_overview ul {
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.infolist_abc .infolist_overview ul li,
.vorsteuerabzugsberechtigte_kfz .infolist_overview ul li {
  background: none;
  padding-left: 0;
}
.infolist_abc .infolist_overview ul li,
.vorsteuerabzugsberechtigte_kfz .infolist_overview ul li {
  float: left;
  width: 280px;
}
.infolist_abc .infolist_overview.main,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main {
  margin-bottom: 24px;
}
.infolist_abc .infolist_overview.main .content > .infolist_overview,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main .content > .infolist_overview {
  width: 600px;
}
.infolist_abc .infolist_overview.main > ul,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul {
  margin-bottom: 8px;
  margin-left: -40px;
  *zoom: 1;
}
.infolist_abc .infolist_overview.main > ul:after,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul:after {
  clear: both;
}
.infolist_abc .infolist_overview.main > ul:after,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul:after,
.infolist_abc .infolist_overview.main > ul:before,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul:before {
  content: '';
  display: table;
}
.infolist_abc .infolist_overview.main > ul > li,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li {
  padding: 0;
  float: left;
  margin-left: 40px;
  width: 280px;
}
.infolist_abc .infolist_overview.main > ul > li > a,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li > a {
  background-color: #efefef;
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  display: inline-block;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 0;
  padding: 8px 15px;
  text-decoration: none;
  display: block;
  text-align: center;
}
.infolist_abc .infolist_overview.main > ul > li > a:hover,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li > a:hover {
  background-color: #e2e2e2;
  color: #35877f;
  cursor: pointer;
}
.infolist_abc .infolist_overview.main > ul > li ul,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li ul {
  background: url('../images/default/doubleline.gif') 0 bottom repeat-x;
  display: inline-block;
  padding-bottom: 15px;
}
.infolist_abc .infolist_overview.main > ul > li ul li.first,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li ul li.first {
  padding-top: 15px;
}
.infolist_abc .infolist_overview.main > ul > li.active > a,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li.active > a {
  background-color: #e2e2e2;
  color: #35877f;
  border-radius: 4px 4px 0 0;
  background-clip: padding-box;
}
.infolist_abc .infolist_section.main,
.vorsteuerabzugsberechtigte_kfz .infolist_section.main {
  margin-left: -40px;
  *zoom: 1;
}
.infolist_abc .infolist_section.main:after,
.vorsteuerabzugsberechtigte_kfz .infolist_section.main:after {
  clear: both;
}
.infolist_abc .infolist_section.main:after,
.vorsteuerabzugsberechtigte_kfz .infolist_section.main:after,
.infolist_abc .infolist_section.main:before,
.vorsteuerabzugsberechtigte_kfz .infolist_section.main:before {
  content: '';
  display: table;
}
.infolist_abc .infolist_section.main h2,
.vorsteuerabzugsberechtigte_kfz .infolist_section.main h2 {
  float: left;
  margin-left: 40px;
  width: 920px;
}
.infolist_abc .infolist_section.main .infolist_overview,
.vorsteuerabzugsberechtigte_kfz .infolist_section.main .infolist_overview {
  text-align: left;
  margin-bottom: 24px;
  float: left;
  margin-left: 40px;
  width: 600px;
}
.infolist_abc .infolist_section.main .infolist_overview > ul,
.vorsteuerabzugsberechtigte_kfz .infolist_section.main .infolist_overview > ul {
  margin-bottom: 24px;
}
.infolist_abc .infolist_section.main .infolist_section,
.vorsteuerabzugsberechtigte_kfz .infolist_section.main .infolist_section {
  float: left;
  margin-left: 40px;
  width: 600px;
}
#infolist_section_abc .infolist_overview ul li {
  margin-right: 15px;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li > a:hover {
  background: transparent;
}
.vorsteuerabzugsberechtigte_kfz .infolist_section.main .infolist_overview {
  width: 600px;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview > ul {
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview > ul li {
  background: none;
  padding-left: 0;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main {
  margin-bottom: 0;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li {
  background: no-repeat 50% 0;
  height: 140px;
  margin-bottom: 24px;
  position: relative;
  float: left;
  margin-left: 40px;
  width: 168px;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li > a,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li.active > a {
  border: 1px solid #c2c2c2;
  background: none;
  bottom: 0;
  box-shadow: none;
  display: block;
  left: 0;
  padding-top: 110px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  border-radius: 4px;
  background-clip: padding-box;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li > a:hover,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li.active > a:hover {
  border-color: #35877f;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main .item_1,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main .item_4,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main .item_7 {
  clear: none;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.sub ul {
  *zoom: 1;
  border-top: 1px solid #c2c2c2;
  margin: 120px 0 24px;
  padding-top: 24px;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.sub ul:after {
  clear: both;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.sub ul:after,
.vorsteuerabzugsberechtigte_kfz .infolist_overview.sub ul:before {
  content: '';
  display: table;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.sub li {
  float: left;
  text-align: left;
  width: 33%;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > li {
  background: no-repeat 50% 5px;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.sub {
  background: no-repeat 100% 0;
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > .item_1,
.vorsteuerabzugsberechtigte_kfz .item_1 .infolist_overview.sub {
  background-image: url('../images/custom/infolists/kastenwagen.jpg');
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > .item_2,
.vorsteuerabzugsberechtigte_kfz .item_2 .infolist_overview.sub {
  background-image: url('../images/custom/infolists/kleinlastkraftwagen.jpg');
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > .item_3,
.vorsteuerabzugsberechtigte_kfz .item_3 .infolist_overview.sub {
  background-image: url('../images/custom/infolists/pritschenwagen.jpg');
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > .item_4,
.vorsteuerabzugsberechtigte_kfz .item_4 .infolist_overview.sub {
  background-image: url('../images/custom/infolists/kleinautobus.jpg');
}
.vorsteuerabzugsberechtigte_kfz .infolist_overview.main > ul > .item_5,
.vorsteuerabzugsberechtigte_kfz .item_5 .infolist_overview.sub {
  background-image: url('../images/custom/infolists/kleinbus.jpg');
}
.vorsteuerabzugsberechtigte_kfz .infolist_section.main h2 {
  margin-left: 0;
}
.visible_for_screen_reader {
  border: 0;
  clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
  height: 0.0625rem;
  left: -624.9375rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: -624.9375rem;
  width: 0.0625rem;
}
.lightbox_overlay {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAEElEQVR42gEFAPr/AAAAALMAuAC0W3h/ywAAAABJRU5ErkJggg==');
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  padding: 15px;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 9800;
  *background-image: url('../images/default/default_overlay.png');
  transition: 0.6s;
}
.lightbox_overlay.show {
  opacity: 1;
  visibility: visible;
}
.lightbox_overlay.show .lightbox {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.lightbox_overlay > .close {
  bottom: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
}
.lightbox_overlay .lightbox {
  opacity: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 9900;
  transition: 0.6s;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
.lightbox_overlay .lightbox img {
  background: #fff;
  display: block;
  max-height: none;
  max-width: none;
}
.lightbox_overlay .lightbox .close {
  background: #262626;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 200;
  border-radius: 0 4px 0 0;
  background-clip: padding-box;
}
.lightbox_overlay .lightbox .close:hover span {
  background-position: -64px -224px;
}
.lightbox_overlay .lightbox .close span {
  display: block;
  height: 32px;
  line-height: 32px;
  width: 32px;
  border-radius: 0 4px 0 0;
  background-clip: padding-box;
  text-indent: -99999px;
  overflow: hidden;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: 0px -224px;
}
.lightbox_overlay .lightbox .description {
  background: #fff;
  bottom: 0;
  left: 0;
  opacity: 1;
  padding: 8px;
  position: absolute;
  right: 0;
  z-index: 300;
  border-radius: 0 0 4px 4px;
  background-clip: padding-box;
}
.lightbox_overlay .lightbox .description:hover {
  opacity: 1;
  transition: opacity 0.3s;
}
.lightbox_overlay .lightbox .image {
  background: #262626;
  border: 1px solid #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  background-clip: padding-box;
}
#news img {
  float: right;
  margin: 0 0 24px 15px;
}
.news_page h4 {
  color: #676d6e;
}
#cloud p {
  text-align: center;
}
#news_functions {
  background: url('../images/default/doubleline.gif') 0 bottom repeat-x;
  margin-bottom: 24px;
  padding-bottom: 30px;
}
#news_functions .text {
  display: inline;
  overflow: auto;
}
#news_functions ul {
  *zoom: 1;
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
#news_functions ul:after {
  clear: both;
}
#news_functions ul:after,
#news_functions ul:before {
  content: '';
  display: table;
}
#news_functions ul li {
  background: none;
  padding-left: 0;
}
#news_functions ul li {
  float: left;
  padding: 0 15px 0 0;
  width: auto;
}
#news_archives {
  float: left;
  width: 200px;
}
#news_archives li.active {
  border: none;
  color: #35877f;
  font-weight: bold;
}
#news_archives_container {
  border-radius: 4px;
  background-clip: padding-box;
  *zoom: 1;
}
#news_archives_container:after {
  clear: both;
}
#news_archives_container:after,
#news_archives_container:before {
  content: '';
  display: table;
}
#news_overview_load {
  overflow: hidden;
}
.news_preview a.active {
  color: #35877f;
}
.news_preview h3 {
  margin: 0;
}
.news_preview img {
  float: left;
  margin: 6px 15px 18px 0;
}
.news_preview .entry {
  *zoom: 1;
}
.news_preview .entry:after {
  clear: both;
}
.news_preview .entry:after,
.news_preview .entry:before {
  content: '';
  display: table;
}
.news_preview .text {
  overflow: hidden;
}
.print_page .icon {
  float: left;
  height: 16px;
  margin: 4px 5px 0 0;
  width: 16px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -512px;
}
.news_rss_feeds {
  margin-bottom: 24px;
}
.news_rss_feeds ul {
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.news_rss_feeds ul li {
  background: none;
  padding-left: 0;
}
.news_rss_feed .icon {
  float: left;
  height: 16px;
  margin: 4px 5px 0 0;
  width: 16px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -352px;
}
.news_rss_feed .text {
  display: block;
  overflow: hidden;
}
body.mobile #news_archives_container {
  border: 0;
}
body.mobile #news_functions li {
  float: none;
}
body.mobile #news_functions .text {
  display: block;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .icon {
    background-size: 224px 576px;
  }
  .print_page .icon {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -512px;
  }
  .news_rss_feed .icon {
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -352px;
  }
}
.onlinetools {
  margin-bottom: 24px;
}
.onlinetools ul {
  list-style-image: none;
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.onlinetools ul li {
  background: none;
  padding-left: 0;
}
.infocenter .onlinetools h3 {
  color: #676d6e;
}
.onlinetool {
  clear: both;
}
.onlinetool a {
  float: right;
  background-color: #efefef;
  border: none;
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  font-size: 14px;
  list-style: none;
  padding: 0 15px;
  text-decoration: none;
  height: 31px;
  line-height: 31px;
}
.onlinetool a.focus,
.onlinetool a:focus {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
  outline: 0;
}
.onlinetool a:hover {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
.onlinetool a:active {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
.onlinetool p {
  margin-bottom: 10px;
}
.iframe_popup_overlay {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAEElEQVR42gEFAPr/AAAAALMAuAC0W3h/ywAAAABJRU5ErkJggg==');
  bottom: 0;
  cursor: pointer;
  left: 0;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9800;
  *background-image: url('../images/default/default_overlay.png');
}
.iframe_popup {
  background: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.8);
  height: 100%;
  margin: 0 auto;
  max-width: 900px;
  opacity: 0;
  position: relative;
  z-index: 9900;
  *bottom: 15px;
  *height: auto;
  *left: 50%;
  *margin-left: -450px;
  *position: absolute;
  *top: 15px;
  *width: 900px;
  border-radius: 4px;
  background-clip: padding-box;
}
.iframe_popup .header {
  background: #35877f;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  height: 32px;
  line-height: 32px;
  padding: 0 17px;
  border-radius: 4px 4px 0 0;
  background-clip: padding-box;
}
.iframe_popup .close {
  border-bottom: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 200;
  border-radius: 0 4px 0 0;
  background-clip: padding-box;
}
.iframe_popup .close:hover span {
  background-position: -64px -224px;
}
.iframe_popup .close:active {
  background-color: #35877f;
}
.iframe_popup .close span {
  display: block;
  height: 32px;
  line-height: 32px;
  width: 32px;
  border-radius: 0 4px 0 0;
  background-clip: padding-box;
  text-indent: -99999px;
  overflow: hidden;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: 0px -224px;
}
.iframe_popup .content {
  bottom: 4px;
  left: 0;
  position: absolute;
  right: 0;
  top: 32px;
}
.iframe_popup iframe {
  border: 0;
  height: 100%;
  width: 100%;
  *bottom: 0;
  *left: 0;
  *position: absolute;
  *top: 0;
  *right: 0;
}
.team_entry {
  margin-bottom: 24px;
  *zoom: 1;
}
.team_entry:after {
  clear: both;
}
.team_entry:after,
.team_entry:before {
  content: '';
  display: table;
}
.team_entry p {
  margin: 0;
}
.team_name,
.team_name_suffix {
  margin: 0;
}
.team_info {
  overflow: hidden;
}
.team_group#e144714 {
  background: url('../images/default/doubleline.gif') 0 bottom repeat-x;
}
.team_group#e144714 h2 {
  padding-top: 0;
}
.team_group#e144714 .contact {
  padding-bottom: 36px;
}
.links_group {
  margin-bottom: 24px;
}
.links_group ul {
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.links_group ul li {
  background: none;
  padding-left: 0;
}
.links_group ul a {
  border: none;
  display: block;
}
.links_group ul li {
  border-bottom: 1px solid #e9eaeb;
  padding: 8px;
}
.links_group ul li:first-child {
  border-top: 1px solid #e9eaeb;
}
.support_bookmarks li {
  padding-right: 32px;
  position: relative;
}
.support_bookmarks .bookmark {
  border-bottom: none;
  display: inline-block;
  height: 15px;
  margin-top: 4px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 15px;
  *margin-top: 0;
  text-indent: -99999px;
  overflow: hidden;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -320px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .support_bookmarks .bookmark {
    background-size: 224px 576px;
    background-image: url('../images/default/default_hires_sprite.png');
    background-repeat: no-repeat;
    background-position: -192px -320px;
  }
}
.map_chart {
  height: 260px;
  background-color: #fff;
  margin: 0 0 24px 0;
  max-width: 100%;
  position: relative;
}
@media (min-width: 640px) {
  .map_chart {
    height: 360px;
  }
}
@media (min-width: 93.75rem) {
  .map_chart {
    height: 460px;
  }
}
.map_chart .drag_area {
  bottom: 0;
  left: 3.75rem;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 1;
}
.map_chart .drag_area.visible {
  visibility: visible;
}
.map_chart .map_chart_wrapper {
  height: 260px;
  border: 1px solid #e9eaeb;
  cursor: move;
  overflow: hidden;
  padding: 6px;
  position: relative;
  z-index: 0;
}
@media (min-width: 640px) {
  .map_chart .map_chart_wrapper {
    height: 360px;
  }
}
@media (min-width: 93.75rem) {
  .map_chart .map_chart_wrapper {
    height: 460px;
  }
}
.map_chart svg path {
  position: relative;
  stroke: #fff;
  transition: fill-opacity 300ms;
}
.map_chart svg path.hasData {
  fill: #35877f;
}
.map_chart svg path.noData {
  fill: #dddddd;
}
.map_chart .map_chart_navigation {
  display: inline-flex;
  flex-direction: column;
  left: 12px;
  position: absolute;
  top: 12px;
}
.map_chart .map_chart_navigation .zoom {
  align-items: center;
  background-color: #ccc;
  border: 1px solid #e9eaeb;
  border-radius: 0;
  color: #676d6e;
  cursor: pointer;
  display: flex;
  font-size: 120%;
  height: 2.5rem;
  justify-content: center;
  margin: 0 0 6px 0;
  padding: 6px 12px;
  width: 2.5rem;
}
.map_chart .map_chart_navigation .zoom.disabled {
  cursor: default;
  opacity: 0.4;
}
.map_chart .output {
  background-color: #fff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
  min-width: 9.375rem;
  opacity: 0;
  padding: 16.8px;
  position: absolute;
  transition: left 300ms, opacity 300ms, top 300ms, visibility 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: hidden;
  z-index: 2;
}
.map_chart .output.show {
  opacity: 1;
  visibility: visible;
}
.map_chart .touch_info {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  display: flex;
  font-weight: 700;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 3;
}
body.reisekosten .switch_view {
  margin-bottom: 12px;
}
body.verbraucherpreisindizes .switch_view {
  cursor: pointer;
  margin-bottom: 12px;
}
.vpi_diagram_grid_wrapper {
  border: 1px solid #e9eaeb;
  margin-bottom: 24px;
  padding: 12px 24px 12px 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.vpi_diagram_grid_wrapper .vpi_diagram {
  line-height: 1;
}
.vpi_diagram_grid_wrapper .vpi_diagram_grid_points_wrapper {
  position: relative;
}
.vpi_diagram_grid_wrapper .vpi_diagram_label_legend_wrapper {
  padding: 8px 0;
  position: relative;
}
@media (min-width: 30rem) {
  .vpi_diagram_grid_wrapper .vpi_diagram_label_legend_wrapper {
    padding-left: 3.75rem;
  }
}
.vpi_diagram_grid_wrapper .vpi_diagram_year_label {
  line-height: 1;
  margin: 0;
  padding: 0;
}
.vpi_diagram_grid_wrapper .vpi_diagram_grid {
  height: 18.75rem;
  padding: 12px 0 12px 0;
}
.vpi_diagram_grid_wrapper .vpi_diagram_grid line {
  stroke: #bbbbbb;
  stroke-width: 0.03125rem;
}
.vpi_diagram_grid_wrapper .vpi_diagram_grid text {
  text-anchor: end;
}
.vpi_diagram_grid_wrapper .vpi_diagram_points {
  height: 18.75rem;
  left: 0;
  padding: 12px 0 12px 3.75rem;
  position: absolute;
  right: 36px;
  top: 0;
  z-index: 1;
}
.vpi_diagram_grid_wrapper .vpi_diagram_points circle {
  cursor: pointer;
}
.vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
.vpi_diagram_grid_wrapper .vpi_diagram_points circle {
  fill: #35877f;
}
.vpi_diagram_grid_wrapper .vpi_diagram_legend line,
.vpi_diagram_grid_wrapper .vpi_diagram_points line {
  stroke: #35877f;
  stroke-width: 0.125rem;
}
.vpi_diagram_grid_wrapper .vpi_diagram_legend {
  height: 2.5rem;
  margin-top: -1.25rem;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 50%;
}
.vpi_diagram_grid_wrapper .vpi_diagram_legend text {
  text-anchor: end;
}
.vpi_diagram_grid_wrapper .vpi_diagram_months_wrapper {
  height: 3.125rem;
  margin-left: 3.75rem;
  position: relative;
}
.vpi_diagram_grid_wrapper .vpi_diagram_months {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.vpi_diagram_grid_wrapper .vpi_diagram_months text {
  text-anchor: middle;
}
.vpi_diagram_grid_wrapper .vpi_diagram_output_wrapper {
  bottom: 12px;
  left: 0;
  margin-left: 3.75rem;
  position: absolute;
  right: 0;
  top: 12px;
}
.vpi_diagram_grid_wrapper .vpi_diagram_output {
  background-color: #fff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
  opacity: 0;
  padding: 16.8px;
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(-120%);
          transform: translateX(-50%) translateY(-120%);
  transition: opacity 300ms, visibility 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: hidden;
  z-index: 2;
}
.vpi_diagram_grid_wrapper .vpi_diagram_output.show {
  opacity: 1;
  visibility: visible;
}
.vpi_diagram_grid_wrapper .vpi_diagram_output.right {
  -webkit-transform: translateX(-90%) translateY(-130%);
          transform: translateX(-90%) translateY(-130%);
}
.vpi_diagram_grid_wrapper .vpi_diagram_output .value {
  margin-bottom: 0;
  white-space: nowrap;
}
.vpi_diagram_grid_wrapper .vpi_diagram text {
  font-size: 85%;
}
@media (min-width: 30rem) {
  .vpi_diagram_grid_wrapper .vpi_diagram text {
    font-size: 100%;
  }
}
a {
  border-bottom: 1px solid #35877f;
  color: #676d6e;
  text-decoration: none;
}
a:active {
  background: none;
}
a:hover {
  border-bottom: 1px solid transparent;
  color: #35877f;
}
a[href^="tel:"] {
  color: #676d6e !important;
  cursor: text;
  text-decoration: none;
}
.more_link {
  float: right;
  background-color: #efefef;
  border: none;
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  font-size: 14px;
  list-style: none;
  padding: 0 15px;
  text-decoration: none;
  height: 31px;
  line-height: 31px;
}
.more_link.focus,
.more_link:focus {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
  outline: 0;
}
.more_link:hover {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
.more_link:active {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
#sitetitle {
  color: #35877f;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  line-height: 25px;
}
#sitetitle span {
  font-size: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #35877f;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
}
.no-fontsmoothing #sitetitle {
  font-family: Times, Georgia, serif;
}
.no-fontsmoothing h1,
.no-fontsmoothing h2,
.no-fontsmoothing h3,
.no-fontsmoothing h4,
.no-fontsmoothing h5,
.no-fontsmoothing h6 {
  font-family: Times, Georgia, serif;
}
table {
  border-color: #e9eaeb;
  width: 100%;
}
td,
th {
  border-color: #e9eaeb;
  padding-left: 8px;
  padding-right: 8px;
}
ul,
ol {
  padding-left: 24px;
}
dt {
  font-weight: bold;
}
ul {
  list-style-type: none;
  padding-left: 0;
}
ul li {
  padding-left: 15px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -544px;
}
#menu {
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  float: left;
  margin-bottom: 40px;
  position: relative;
  z-index: 100;
  *zoom: 1;
}
#menu li {
  background: none;
  padding-left: 0;
}
#menu:after {
  clear: both;
}
#menu:after,
#menu:before {
  content: '';
  display: table;
}
#menu a.active,
#menu a.activenode {
  color: #35877f;
  font-weight: bold;
}
#menu > li {
  float: left;
  position: relative;
  white-space: nowrap;
}
#menu > li > a {
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  display: block;
  padding: 55px 22px 4px 0;
  text-decoration: none;
}
#menu > li > a.active,
#menu > li > a:hover,
#menu > li > a.hover {
  color: #35877f;
  background-color: #fff;
}
#menu > li:hover > ul,
#menu > li.hover > ul {
  display: block;
}
#menu ul {
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff url('../images/default/menu220.gif') 15px bottom no-repeat;
  display: none;
  list-style-image: none;
  margin: -3px 0 0 -15px;
  min-width: 220px;
  padding: 15px 15px 50px;
  position: absolute;
  z-index: 100;
}
#menu ul li {
  background: none;
  padding-left: 0;
}
#menu ul a {
  border-bottom: none;
  display: block;
}
#menu ul a.active,
#menu ul a.activenode,
#menu ul a:hover {
  color: #35877f;
  font-weight: normal;
  padding-left: 13px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -544px;
}
#menu #menu_e117849 ul {
  background: #fff url('../images/default/menu150.gif') 15px bottom no-repeat;
  min-width: 150px;
}
body {
  color: #676d6e;
}
html {
  background-color: #efefef;
}
#page {
  min-width: 960px;
}
#wrapper {
  background: #fff url('../images/default/watermark.png') 0 bottom no-repeat;
  margin: 0 auto;
  min-width: 960px;
  overflow: hidden;
  max-width: 1000px;
}
.startpage h2 {
  font-size: 15px;
}
.startpage .startpage_news h2 {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  padding: 0 0 0 13px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -544px;
}
.startpage .onlinetool {
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -544px;
}
.startpage .onlinetool a {
  background-color: #fff;
  border-bottom: 1px solid #35877F;
  float: none;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  height: 0;
  line-height: 0;
  margin: 0;
  margin-left: 13px;
  padding: 0;
}
.startpage .onlinetool a:hover {
  border-bottom: 1px solid transparent;
}
.startpage .span20 {
  margin-bottom: 35px;
  min-height: 332px;
  position: relative;
}
.startpage div.quicklinks {
  background: url('../images/default/doubleline.gif') 0 0 repeat-x;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
.startpage div.quicklinks .more_link {
  background-image: none;
  margin-top: 25px;
  padding: 0;
}
.startpage div.quicklinks .more_link a {
  display: block;
  padding: 0 15px;
}
#header_bar {
  background-color: #4a4d4e;
  border-bottom: 4px solid #e1e2e2;
  height: 31px;
  padding-top: 7px;
  width: 100%;
}
#header_bar #slogan {
  color: #c7c7c7;
  float: left;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
}
#header_bar #links {
  float: right;
}
#header_bar #links a,
#header_bar #links h1 {
  color: #878f8f;
  border-bottom: none;
  font-size: 12px;
  margin-left: 10px;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-weight: normal;
  display: inline;
}
#header_bar #links a:hover,
#header_bar #links h1:hover {
  color: #fff;
}
#header_image {
  clear: both;
  position: relative;
}
#logo {
  border: none;
  float: right;
  margin-top: 30px;
}
#img_overlay {
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  font-size: 22px;
  text-align: center;
}
#img_overlay p {
  margin: 13px 0 0 0;
}
#divider {
  background: url('../images/default/divider.gif') no-repeat;
  height: 19px;
  margin: 20px auto 40px auto;
  width: 920px;
}
#sidebar {
  margin-top: 43px;
}
#sidebar h2 {
  color: #676d6e;
  font-size: 15px;
}
#sidebar .widget_menu {
  background: url('../images/default/doubleline.gif') 0 bottom repeat-x;
  margin-bottom: 24px;
  padding-bottom: 10px;
}
#sidebar .widget_menu ul {
  list-style-image: none;
  list-style-type: none;
  padding-left: 0;
}
#sidebar .widget_menu ul li {
  background: none;
  padding-left: 0;
}
#sidebar .widget_menu ul li a {
  border-bottom: none;
  display: block;
}
#sidebar .widget_menu ul li a.active,
#sidebar .widget_menu ul li a:hover,
#sidebar .widget_menu ul li a.hover {
  color: #35877f;
  padding-left: 13px;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: -192px -544px;
}
#sidebar .sidebar_news .widget_menu {
  background: none;
  padding-bottom: 0;
}
#sidebar .sidebar_news .widget_menu li {
  margin-bottom: 15px;
}
#sidebar .sidebar_news .news_archiv_btn p {
  background: url('../images/default/doubleline.gif') 0 bottom repeat-x;
  padding-bottom: 40px;
}
#sidebar .sidebar_news .news_archiv_btn a {
  display: inline-block;
  background-color: #efefef;
  border: none;
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  font-size: 14px;
  list-style: none;
  padding: 0 15px;
  text-decoration: none;
  height: 31px;
  line-height: 31px;
}
#sidebar .sidebar_news .news_archiv_btn a.focus,
#sidebar .sidebar_news .news_archiv_btn a:focus {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
  outline: 0;
}
#sidebar .sidebar_news .news_archiv_btn a:hover {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
#sidebar .sidebar_news .news_archiv_btn a:active {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
#footer {
  margin: 85px auto 35px;
}
#footer p {
  margin-bottom: 0;
}
#footer #logo_and_author {
  border-bottom: 3px solid #35877f;
  margin-bottom: 8px;
  padding-bottom: 8px;
  position: relative;
}
#footer #footer_logo {
  display: inline-block;
  font-size: 19px;
  margin-bottom: 0;
}
#footer #footer_logo:first-letter {
  font-family: 'cantata_oneregular', Times, Georgia, serif;
}
#footer #author {
  border-bottom: none;
  bottom: 0;
  height: 9px;
  padding-bottom: 12px;
  position: absolute;
  right: 0;
  width: 76px;
  text-indent: -99999px;
  overflow: hidden;
  background-image: url('../images/default/default_sprite.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
#breadcrumbs {
  display: none;
}
.infocenter .overview.links h3 {
  color: #676d6e;
}
.overview {
  margin-bottom: 50px;
}
.overview h2 a,
.overview .entry a {
  display: inline-block;
  background-color: #efefef;
  border: none;
  border-bottom: 3px solid #35877f;
  color: #676d6e;
  font-family: 'cantata_oneregular', Times, Georgia, serif;
  font-size: 14px;
  list-style: none;
  padding: 0 15px;
  text-decoration: none;
  height: 31px;
  line-height: 31px;
}
.overview h2 a.focus,
.overview .entry a.focus,
.overview h2 a:focus,
.overview .entry a:focus {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
  outline: 0;
}
.overview h2 a:hover,
.overview .entry a:hover {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
.overview h2 a:active,
.overview .entry a:active {
  background: none;
  background-color: #e2e2e2;
  border-bottom: 3px solid #35877f;
  color: #35877f;
}
.overview .entry > a {
  float: right;
  margin-bottom: 24px;
}
.overview .entry > a:hover {
  background-color: #e2e2e2;
  color: #35877f;
  cursor: pointer;
}
.wt_kammern_imprint {
  font-size: 13px;
  line-height: 19px;
}
.wt_kammern_imprint h3 {
  font-size: 13px!important;
}
#cloud {
  line-height: 1.3;
}
#cloud a {
  margin: 0 3px;
}
